<template>
  <div class="view pa24">

    <el-form :model="serchParams" class="demo-form-inline" :inline="true">
          <el-row style="padding-top: 20px;">
<!--              <el-form-item v-if="labelName" :label="labelName" label-width="100px" label-position="left" class="fromItem" prop="dealerId">-->
<!--                <el-select v-model="serchParams.dealerId" placeholder="请选择" clearable>-->
<!--                  <el-option-->
<!--                    v-for="item in dealerOptions"-->
<!--                    :key="item.id"-->
<!--                    :label="item.dealerName"-->
<!--                    :value="item.id">-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
               <el-form-item label="生产批次" label-width="72px" label-position="left" class="fromItem" >
                <el-input v-model="serchParams.produceNumber"></el-input>
              </el-form-item>
              <el-form-item label="状态" label-width="100px" label-position="left" class="fromItem" prop="status">
                <el-select v-model="serchParams.status" placeholder="请选择">
                  <el-option
                    v-for="item in statusSelect"
                    :key="item.label"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="订单时间" label-width="100px" label-position="left" class="fromItem" prop="startTime">
                <el-date-picker
                  v-model="serchParams.timeRange"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  >
                </el-date-picker>
              </el-form-item>
              <el-button type="primary" @click="search">搜索</el-button>
              <el-button @click="resetSearch">重置</el-button>
          </el-row>
      </el-form>
    <div class="d-flex">
      <el-button class="ma" type="primary" @click="handleAddOrder('add')">+添加入库单</el-button>
    </div>
    <commonTable
      :tableData="tableData"
      :total="total"
      :currentPage="pageNum"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:table>

        <!-- <el-table-column
            prop="classId"
            align="center"
            label="类别"
        /> -->
        <!-- <span class="copyTxt" @click="copy(scope.row.handle)"><img src="../../assets/images/fz.png" /></span> -->
        <el-table-column
          prop="lotNumber"
          align="center"
          label="单号"
		      width="360"
        >
        <template slot-scope="scope">
            {{scope.row.lotNumber}}<span class="copyTxt" @click="copy(scope.row.lotNumber)"><img src="../../assets/images/fz.png" /></span>
        </template>
        </el-table-column>
        <!-- <el-table-column
          prop=""
          align="center"
          label="产品信息"
        /> -->
         <el-table-column
            prop="warehouseName"
            align="center"
            label="仓库"
        />
        <!-- <el-table-column
            prop="produceNumber"
            align="center"
            label="生产批次"
        >
          <template slot-scope="scope" >
            {{scope.row.produceNumber}}<span class="copyTxt" @click="copy(scope.row.produceNumber)"><img src="../../assets/images/fz.png" v-if="scope.row.produceNumber"/></span>
          </template>
        </el-table-column> -->
        <el-table-column
            prop="packNum"
            align="center"
            label="入库数量"
        >
          <template slot-scope="scope">
            {{scope.row.boxNum||0}}箱 + {{scope.row.packNum||0}}盒
          </template>
        </el-table-column>
        <el-table-column
            prop="packNum"
            align="center"
            label="已入库数量"
        >
          <template slot-scope="scope">
            <span :class="(scope.row.status!== 0 && (scope.row.finishBoxNum!==scope.row.boxNum)) ? 'redColor' : ''">{{scope.row.finishBoxNum||0}}</span>箱 +
            <span :class="(scope.row.status!== 0 && (scope.row.finishPackNum!==scope.row.packNum)) ? 'redColor' : ''">{{scope.row.finishPackNum||0}}</span>盒
          </template>
        </el-table-column>
        <el-table-column
            prop="createTime"
            align="center"
            label="创建时间"
        />
         <el-table-column
            prop="status"
            align="center"
            label="状态"
         >
         <template slot-scope="scope">
           <span v-if="scope.row.status === 0" class="greenTag">
             未开始
           </span>
           <span v-else-if="scope.row.status === 1" class="greenTag">
             进行中
           </span>
           <span v-else-if="scope.row.status === 2"  class="greyTag">
             已完成
           </span>
           <span v-else-if="scope.row.status === 3"  class="greyTag">
             已关闭
           </span>
         </template>
         </el-table-column>
         <el-table-column
            prop="remarks"
            align="center"
            label="备注"
         ></el-table-column>
        <el-table-column align="center" width="200" label="操作">
          <template slot-scope="scope">
            <el-button type="text"  v-if="scope.row.status == 0" @click="handleAddOrder('edit', scope.row)">编辑</el-button>
            <el-button type="text" v-if="scope.row.status == 0" @click="delOrder(scope.row)" class="redBtn">删除</el-button>
            <el-button type="text"  @click="handleAddOrder('detail',scope.row)" >查看</el-button>

          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { fetchCkProduceList,fetchDeleteCrkProduce} from '@/api/warehouseManage.js'
import { pageClassList } from '@/api/customPageClass.js'
import { fetchDealList } from '@/api/companyManage.js'
import { selectWarehouseList } from '@/api/warehouseManage.js'
import {getMySetting} from "@/api/crkStrategy";

export default {
  name: "dealerList",
  components: {
    commonTable,
  },
  data() {
    return {
        tableData: [],
        pageSize: 10,
        pageNum: 1,
        total: 0,
        wareStatus: 2,
        serchParams: {
          classId: '',
          dealerId:'',
          timeRange: [],
          produceNumber: '',
          status: '',
        },
        statusSelect: [
          {
            label: '全部',
            value: '',
          },
          {
            label: '未开始',
            value: 0,
          },
          {
            label: '进行中',
            value: 1,
          },
          {
            label: '已完成',
            value: 2,
          },
          {
            label: '已关闭',
            value: 3,
          },
        ],
        classOptions:[],
        dealerOptions:[],
        storeOptions:[],
        labelName: '',
      hasSetting:false
    };
  },
  created() {
    this.getSetting()
    // 获取所有分类
    this.getClassList()
    // 获取所有仓库列表
    // this.getStoreList()
    this.getList()
  },
  methods: {
    getSetting(){
      getMySetting().then(res=>{
        console.log(res.data)
        if (res.data){
          this.hasSetting = true
        }
      })
    },
    classIdChange(){

    },
	  copy(context) {
          // 创建输入框元素
          let oInput = document.createElement('input');
          // 将想要复制的值
          oInput.value = context;
          // 页面底部追加输入框
          document.body.appendChild(oInput);
          // 选中输入框
          oInput.select();
          // 执行浏览器复制命令
          document.execCommand('Copy');
          // 弹出复制成功信息
          this.$message.success('复制成功');
          // 复制后移除输入框
          oInput.remove();
	    },
      handleSizeChange(num) {
        this.pageSize = num;
        this.pageNum = 1;
        this.getList();
      },
      handleCurrentChange(num) {
        this.pageNum = num;
        this.getList();
      },
      handleAddOrder(type, data) {
          // this.$refs.addOrderDialog.openDialog(type, data)
          this.$router.push({
            path: '/crk/crkProduceAdd',
            query:{hasSetting:this.hasSetting}
          })
          if(data) {
            this.$router.push({ path: `/crk/crkProduceAdd`, query: { data: JSON.stringify(data),type:type,hasSetting:this.hasSetting} })
          }
      },

      // 过滤掉空的children
      formatData(data) {
        for (var i = 0; i < data.length; i++) {
          if (data[i].children == null || data[i].children.length == 0) {
            data[i].children = undefined;
          } else if (data[i].children.length > 1) {
            this.formatData(data[i].children);
          }
        }
        return data;
      },
      getClassList() {
        let params={
          pageNum: 1,
          pageSize: 999,
          type:0
        }
        pageClassList(params).then(res => {
          this.classOptions = this.formatData(res.data.list)
        })
      },
      getStoreList() {
        let params={
          pageNum: 1,
          pageSize: 999,
          companyId: JSON.parse(localStorage.getItem('info')).companyId,
        }
        selectWarehouseList(params).then(res => {
          this.storeOptions = res.data.list
        })
      },
      search() {
        this.pageNum = 1
        this.getList()
      },
      resetSearch() {
        this.serchParams.classId = ''
        this.serchParams.timeRange = []
        this.serchParams.produceNumber = ''
        this.serchParams.status = ''
        this.pageNum = 1
        this.getList()
      },
      getList() {
        let params={
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          startTime: this.serchParams.timeRange && this.serchParams.timeRange.length > 0 ? this.serchParams.timeRange[0] : '',
          endTime: this.serchParams.timeRange && this.serchParams.timeRange.length > 0 ? this.serchParams.timeRange[1] : '',
          produceNumber: this.serchParams.lotNumber,
          status: this.serchParams.status
        }
        fetchCkProduceList(params).then(res => {
          this.tableData = res.data.list
          this.total = res.data.total
        })
      },
     delOrder(row) {
       this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         type: 'warning'
       }).then(() => {
         fetchDeleteCrkProduce({id: row.id}).then(res => {
           if(res.code === 200) {
             this.$message({
               type: 'success',
               message: '删除成功!'
             });
             this.getList()
           }
         })
       }).catch(() => {
         this.$message({
           type: 'info',
           message: '已取消删除'
         });
       });

     }
  }
}
</script>

<style lang="scss" scoped>
	.name_status {
		display: inline-block;
		width: 26px;
		height: 17px;
		line-height: 15px;
		background: rgba(63, 114, 246, 0.11);
		border: 1px solid #3F72F6;
		border-radius: 4px;
		font-size: 12px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #3F72F6;
		text-align: center;
	}

	.name_status1 {
		display: inline-block;
		width: 26px;
		height: 17px;
		line-height: 15px;
		background: rgba(243, 136, 46, 0.11);
		border: 1px solid #F3882E;
		border-radius: 4px;
		font-size: 12px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #F3882E;
		text-align: center;
	}

	.copyTxt {
		display: inline-block;
		margin-left: 5px;
		cursor: pointer;
		color: #3F72F6;
	}
  .greenTag{
    color: #fff;
    background: #08A89B;
    border-radius: 6px;
    padding: 3px 5px;
    overflow: hidden;

  }
  .greyTag {
    background: #e5e5e5;
    color: #333;
    border-radius: 6px;
    padding: 3px 5px;
    overflow: hidden;

  }
  .redBtn,.redColor{
    color: #f00;
  }
</style>

